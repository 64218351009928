var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{staticClass:"registration"},[_c('div',{staticClass:"entrance-modal"},[_c('div',{staticClass:"entrance-modal__head text-center"},[_c('a',{staticClass:"entrance-modal__logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@assets/images/logo.svg"),"height":"34","alt":""}})]),(_vm.registrationStep === _vm.registrationStepEnum.InputRegistrationData)?[_c('p',{staticClass:"entrance-modal__title"},[_vm._v(_vm._s(_vm.$t('registration.title')))]),_c('p',{staticClass:"entrance-modal__text text-muted"},[_vm._v(_vm._s(_vm.$t('registration.description')))])]:_vm._e(),(_vm.registrationStep === _vm.registrationStepEnum.InputPassword)?[_c('p',{staticClass:"entrance-modal__title"},[_vm._v(_vm._s(_vm.$t('registration.passwordTitle')))]),_c('p',{staticClass:"entrance-modal__text text-muted"},[_vm._v(_vm._s(_vm.$t('registration.passwordDesc')))])]:_vm._e(),(_vm.registrationStep === _vm.registrationStepEnum.InputActivatedCode)?[_c('p',{staticClass:"entrance-modal__title"},[_vm._v(_vm._s(_vm.$t('registration.confirmPhone')))]),_c('p',{staticClass:"entrance-modal__text text-muted _phone-activation"},[_vm._v(_vm._s(_vm.$t('registration.enterCode')))])]:_vm._e()],2),(_vm.registrationStep === _vm.registrationStepEnum.InputRegistrationData)?_c('ValidationObserver',{key:"inputRegistrationData",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var passes = ref.passes;
return [_c('BForm',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.goToPasswordStep)}}},[_c('BTextInputValidation',{attrs:{"rules":"required|email","name":"email","type":"email","placeholder":_vm.$t('registration.email'),"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_c('BTextInputValidation',{attrs:{"rules":"required","name":"firstName","placeholder":_vm.$t('registration.name'),"required":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"firstName"}}),_c('b-form-group',{staticClass:"mt-4"},[_c('b-button',{staticClass:"form-group__btn",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('common.next'))+" ")])],1)],1)]}}],null,false,4275716681)}):_vm._e(),(_vm.registrationStep === _vm.registrationStepEnum.InputPassword)?_c('ValidationObserver',{key:"inputPassword",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var passes = ref.passes;
return [_c('BForm',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.tryRegistration)}}},[_c('BTextInputValidation',{attrs:{"rules":"required|min:6","name":"password","type":"password","placeholder":_vm.$t('registration.password'),"required":"","autocomplete":"new-password","isPassword":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"password"}}),_c('BTextInputValidation',{attrs:{"rules":"required|confirmed:password","name":"repeatPassword","type":"password","placeholder":_vm.$t('registration.repeatPassword'),"required":"","isPassword":""},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"repeatPassword"}}),_c('PasswordValidation',{attrs:{"password":_vm.password},on:{"valid":_vm.onValidPass}}),_c('b-form-group',{staticClass:"mt-4"},[_c('b-button',{staticClass:"form-group__btn",attrs:{"type":"submit","variant":"primary","disabled":_vm.isTryingToRegistration || invalid || !_vm.validPass}},[_vm._v(" "+_vm._s(_vm.$t('common.next'))+" ")])],1)],1)]}}],null,false,1751353794)}):_vm._e(),(_vm.registrationStep === _vm.registrationStepEnum.InputActivatedCode)?_c('ValidationObserver',{key:"inputActivatedCode",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var passes = ref.passes;
return [_c('div',{staticClass:"activation__text text-muted"},[_vm._v(_vm._s(_vm.$t('registration.activatedCode')))]),_c('BForm',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.tryActivatedCode)}}},[_c('BCodeInputValidation',{attrs:{"name":"activatedCode"},model:{value:(_vm.activatedCode),callback:function ($$v) {_vm.activatedCode=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"activatedCode"}}),_c('b-form-group',[_c('b-button',{staticClass:"form-group__btn",attrs:{"type":"submit","variant":"primary","disabled":_vm.isTryingToActivatedCode || invalid}},[_vm._v(" "+_vm._s(_vm.$t('common.confirm'))+" ")])],1),_c('div',{staticClass:"form-group__footer d-flex align-items-center flex-column mt-3"},[(_vm.timerCount > 1)?_c('div',{staticClass:"activation__text text-muted"},[_vm._v(" "+_vm._s(_vm.$t('registration.repeatSend1'))+" "+_vm._s(_vm.timerCount)+" "+_vm._s(_vm.$t('registration.repeatSend2'))+" ")]):_c('div',{staticClass:"activation__text text-muted"},[_c('button',{staticClass:"link",attrs:{"type":"button"},on:{"click":function($event){return _vm.tryRegistration()}}},[_vm._v(" "+_vm._s(_vm.$t('registration.sendCode'))+" ")])])])],1)]}}],null,false,3437610282)}):_vm._e(),_c('div',{staticClass:"form-group__text text-center text-muted"},[(_vm.registrationStep === _vm.registrationStepEnum.InputRegistrationData)?_c('div',{staticClass:"text-center mb-2"},[_c('i18n',{attrs:{"path":"login.iAgree"}},[_vm._v(" «"+_vm._s(_vm.$t('common.next'))+"» "),_c('a',{attrs:{"href":"https://butlerwallet.com/termsofuse.php","target":"_blank","rel":"noreferrer"}},[_vm._v(" "+_vm._s(_vm.$t('login.termsConditions'))+" ")])])],1):_vm._e()]),(_vm.registrationStep === _vm.registrationStepEnum.InputRegistrationData)?_c('div',{staticClass:"form-group__footer d-flex align-items-center flex-column mt-3"},[_c('router-link',{staticClass:"link d-inline-block",attrs:{"to":"/login"}},[_vm._v(_vm._s(_vm.$t('common.login')))])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }